<template>
	<v-dialog persistent max-width="764px" ref="updateDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title>
					<span class="text-h5 mb-3">Update Agency Fund</span>
				</v-card-title>
				<v-card-text>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense label="Start Wages*" :rules="rules.start" :error-messages="errors.start" v-model="object.start"></v-text-field>
                        </v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="End Wages*" :rules="rules.end" :error-messages="errors.end" v-model="object.end"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-menu offset-y transition="scale-transition" min-width="auto" :close-on-content-click="false" v-model="effectiveDateMenu">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field dense clearable label="Date of Effective*" v-bind="attrs" v-on="on" :rules="rules.effective_date" :error-messages="errors.effective_date" v-model="object.effective_date"></v-text-field>
                                </template>
                                <v-date-picker no-title color="primary" v-model="object.effective_date" @input="effectiveDateMenu = false"></v-date-picker>
                            </v-menu>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Fund*" :rules="rules.fund" :error-messages="errors.fund" v-model="object.fund"></v-text-field>
						</v-col>
					</v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="save">Save Changes</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { copyObject } from '@/utils/helper'

export default {
	name: 'AgencyFundUpdateDialog',
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			effectiveDateMenu: false,
			object: {
                agency: this.$route.params.id,
                start: '',
                end: '',
                effective_date: '',
                fund: ''
			},
			rules: {
                effective_date: [
                    (value) => !!value || 'Effective date is required'
                ],
                start: [
                    (value) => {
                        if(!value){
                            return 'Start wage is required'
                        }

                        if(isNaN(value)) {
                            return "Start wage must a numeric value"
                        }

                        return true
                    }
                ],
                end: [
                    (value) => {
                        if(!value){
                            return 'End wage is required'
                        }

                        if(isNaN(value)) {
                            return "End wage must a numeric value"
                        }

                        return true
                    }
                ],
                fund: [
                    (value) => {
                        if(!value){
                            return 'Fund is required'
                        }

                        if(isNaN(value)) {
                            return "Fund must a numeric value"
                        }

                        return true
                    }
                ],
			},
			errors: {}
		}
	},
	computed: {
		updateDialog() {
			return this.$refs.updateDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.form.reset()
			this.form.resetValidation()
			this.object = { agency: this.$route.params.id, start: '', end: '', effective_date: '', fund: '' }
			this.errors = {}
		},
		save: function() {
			if(this.form.validate()){
				this.loading = true
				const object = copyObject(this.object, ['id'])
                this.$store.dispatch('epanel/setting/updateAgencyFund', { id: this.object.id, object: object}).then((response) => {
                    this.close()
                    this.$emit('created', { message: response.message })
                }).catch((errors) => {
                    this.errors = errors.data.errors
                    this.loading = false
                })
			}
		}
	}
}

</script>