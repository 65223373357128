<template>
    <v-dialog persistent max-width="764px" v-model="dialog">
        <v-card>
            <v-card-title>Delete Fund</v-card-title>
            <v-card-text class="pb-0">
                <v-alert dense outlined type="error" v-if="message"><span v-text="message"></span></v-alert>
                <v-alert dense class="text-subtitle-1" type="warning">
                    <span>Are you sure that you want to delete this fund?</span>
                </v-alert>
            </v-card-text>
            <v-card-actions class="text-right pr-6 pl-6">
                <v-spacer></v-spacer>
                <v-btn text @click="close">Cancel</v-btn>
                <v-btn text color="red" :loading="loading" @click="remove">Delete</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'AgencyFundDeleteDialog',
    data() {
        return {
            dialog: false,
            loading: false,
            object: {
                fund: ''
            },
            message: ''
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.loading = false
            this.object = { fund: '' }
            this.message = ''
        },
        updateObject: function(item) {
            this.object = item
        },
        remove: function() {
            this.loading = true

            this.$store.dispatch('epanel/setting/deleteAgencyFund', { id: this.object.fund }).then((response) => {
                this.close()
                this.$emit('deleted', { message: response.message })
            }).catch((errors) => {
                this.message = errors.message
                this.loading = false
            })
        }
    }
}

</script>