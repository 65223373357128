<template>
	<div>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-sheet color="white" elevation="0" class="pa-0">
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                            <p class="text-h4 mb-0" v-text="object.name"></p>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2" class="text-right">
                            <v-tooltip bottom><template v-slot:activator="{ on, attrs }"><v-btn icon v-bind="attrs" v-on="on" @click="back"><v-icon>mdi-arrow-left</v-icon></v-btn></template><span>Back</span></v-tooltip>
                        </v-col>
					</v-row>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <display-label label="Code" :text="object.code"></display-label>
						</v-col>
                    </v-row>
                </v-sheet>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<fund-list ref="branchList"></fund-list>
			</v-col>
		</v-row>
	</div>
</template>


<script>

import DisplayLabel from '../../../shared/DisplayLabel.vue'
import AgencyFundList from './fund/AgencyFundList.vue'

export default {
	name: 'AgencyInfo',
	components: {
        displayLabel: DisplayLabel,
        fundList: AgencyFundList,
	},
	data () {
		return {
			loading: false,
			selectedIndex: -1,
			object: {
                name: '',
                code: ''
			}
		}
	},
	mounted: function() {
        this.get()
	},
	methods: {
        back: function() {
            this.$router.replace('/epanel/setting/agency')
        },
        get: function() {
			this.loading = true
			this.$store.dispatch('epanel/setting/getAgency', this.$route.params).then((response) => {
                this.object = response.data.object
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		}
	}
}

</script>