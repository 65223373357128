<template>
	<div>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="px-1">
				<v-btn small color="primary" class="mx-2 elevation-0" :ripple="false" @click='openCreateDialog'>
					<v-icon small left>mdi-plus</v-icon> Create Fund
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
					<template v-slot:item='{ item, index }'>
						<tr>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.start"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.end"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.effective_date.text"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.fund"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
								<v-menu bottom offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-btn small icon v-bind="attrs" v-on="on">
											<v-icon>mdi-dots-horizontal</v-icon>
										</v-btn>
									</template>
									<v-list dense>
										<v-list-item @click="openUpdateDialog(index, item)">
											<v-list-item-title>Update Fund</v-list-item-title>
										</v-list-item>
										<v-list-item @click="openDeleteDialog(index, item)">
											<v-list-item-title>Delete Fund</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row v-if="!loading && pagination.total_pages > 1">
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-pagination circle :length="pagination.total_pages" :total-visible="7" v-model="filters.page" @input="get"></v-pagination>
			</v-col>
		</v-row>
		<create-dialog ref="createDialog" @created="updateItems"></create-dialog>
		<update-dialog ref="updateDialog" @updated="updateItem"></update-dialog>
		<delete-dialog ref="deleteDialog" @deleted="updateItems"></delete-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import { copyObject } from '@/utils/helper'
import MessageNotifier from '../../../../shared/MessageNotifier.vue'
import AgencyFundCreateDialog from './AgencyFundCreateDialog.vue'
import AgencyFundUpdateDialog from './AgencyFundUpdateDialog.vue'
import AgencyFundDeleteDialog from './AgencyFundDeleteDialog.vue'

export default {
	name: 'AgencyFundList',
    components: {
        messageNotifier: MessageNotifier,
        deleteDialog: AgencyFundDeleteDialog,
		createDialog: AgencyFundCreateDialog,
		updateDialog: AgencyFundUpdateDialog
	},
	data () {
		return {
			loading: false,
			selectedIndex: -1,
            filters: {
                agency: this.$route.params.id,
                page: 1
            },
			items: [],
			headers: [],
			pagination: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		updateDialog() {
			return this.$refs.updateDialog
		},
        deleteDialog() {
            return this.$refs.deleteDialog
        },
		notifier() {
            return this.$refs.notifier
        },
        dialog() {
            return this.$refs.dialog
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
		get: function() {
			this.loading = true
			this.items = []

			this.$store.dispatch('epanel/setting/getAgencyFunds', this.filters).then((response) => {
				this.items = response.data.items
				this.headers = response.data.headers
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		openCreateDialog: function() {
			this.createDialog.open()
		},
		openUpdateDialog: function(index, item) {
            this.selectedIndex = index
			this.updateDialog.object = copyObject(item)
			this.updateDialog.object.effective_date = item.effective_date.value
			this.updateDialog.open()
		},
		openDeleteDialog: function(index, item) {
            this.deleteDialog.updateObject({fund:item.id})
            this.deleteDialog.open()
		},
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        updateItems: function(item) {
            this.filters.page = 1
            this.get()
            this.openNotifier(item.message)
		},
		updateItem: function(item) {
            this.$set(this.items, this.selectedIndex, item.object)
            this.openNotifier(item.message)
		}
	}
}

</script>